@import "../../../../App.scss";

.succession_doc_loading_container {
  width: 100%;
  .heading {
    @include font_gt_walsheim_bold;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    width: 70%;
    margin-bottom: 10px;
  }
  .sub_heading {
    height: 36px;
    width: 100%;
    border-radius: var(--size-small, 12px);
    margin-bottom: 14px;
  }
  .title {
    height: var(--24, 24px);
    width: 100%;
    border-radius: var(--size-small, 12px);
    margin-bottom: 14px;
  }
  .details_loader {
    width: 100%;
    height: 188px;
    border-radius: var(--size-small, 12px);
    margin-bottom: 24px;
  }
}
