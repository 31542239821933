@font-face {
  // font-family: 'gt-walsheim';
  font-family: "gt-walsheim-regular";
  src: url("../assets/fonts/gt-walsheim-regular.woff2") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  // font-family: 'gt-walsheim';
  font-family: "gt-walsheim-medium";
  src: url("/assets/fonts/gt-walsheim-medium.woff2") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  // font-family: 'gt-walsheim';
  font-family: "gt-walsheim-bold";
  src: url("/assets/fonts/gt-walsheim-bold.woff2") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@mixin font_gt_walsheim {
  // font-family: 'gt-walsheim';
  font-family: "gt-walsheim-regular", sans-serif;
  font-weight: 400;
}
@mixin font_gt_walsheim_medium {
  font-family: "gt-walsheim-medium", sans-serif;
  // font-family: 'gt-walsheim';
  font-weight: 500;
}
@mixin font_gt_walsheim_bold {
  font-family: "gt-walsheim-bold", sans-serif;
  // font-family: 'gt-walsheim';
  font-weight: 700;
}
