@import "../../../App.scss";

.notes_wrapper {
  border-radius: 4px;
  padding: 16px 0;
  margin-bottom: 32px;
  display: flex;
  .left_wrapper {
    width: 56px;
    margin-right: 12px;
    .initials {
      width: 56px;
      height: 56px;
      border-radius: 95px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      @include font_gt_walsheim_bold;
    }
  }
  .right_wrapper {
    width: calc(100% - 68px);
    .user_info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .user_name {
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim_medium;
        margin-right: 16px;
      }
      .last_modified {
        font-size: 14px;
        line-height: 18px;

        .edited_tag {
          margin-left: 5px;
        }
      }
    }
    .note_content {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim;
      // TODO: If require make note section three liner by uncommenting below line
      // @include ellipsis_three;
      // min-height: 48px;
      margin-bottom: 16px;
    }

    .action_btns {
      display: flex;
      font-size: 16px;
      line-height: 20px;
      @include font_gt_walsheim_bold;

      .action_edit {
        margin-right: 16px;
        cursor: pointer;
      }
      .action_delete {
        cursor: pointer;
      }
    }
  }
  .input_wrapper {
    margin-bottom: 8px;
    .formatter_wrapper {
      margin-bottom: 32px;
    }
    .input_text_area {
      width: 100%;
      height: 56px;
      border-radius: 12px;
      display: flex;
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
      @include font_gt_walsheim_medium;
      &.text_area_onchange {
        height: 186px;
        resize: none;
      }
    }
    .btn_wrapper {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 290px;
      margin-left: auto;
    }
  }
  .tab_tag_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .tab_label {
      width: auto;
      //display: inline-flex;
      padding: 3px 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 24px;
      @include font_gt_walsheim_medium;
      border-radius: 4px;
      margin-right: 12px;
    }
  }
}
