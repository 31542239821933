
.report_card {
  padding: 16px 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .success_message {
      font-size: 16px;
      font-weight: bold;
      color: #50c878;
    }

    .create_report_button {
      background-color: #000;
      color: #fff;
      font-size: 16px;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .report_card {
    background-color: #1C1C1E;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;

    .report_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid #000000;
      margin-bottom: 16px;
      .report_title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;

        .report_id {
          font-weight: normal;
          color: #888;
        }
      }

      .report_details {
        font-size: 12px;
        color: #bbb;
      }
      .report_actions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;          
        .preview_button {
          margin-right: 24px;
          border: 1px solid #fff;
          background-color: #1e1e1e;
          font-size: 14px;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
        }
        .download_button {
          font-size: 14px;
          padding: 8px;
          border-radius: 50%;
          cursor: pointer;
          background-color: #000;
        }
      }
    }

    .ai_summary {
      margin-bottom: 16px;
      padding: 12px;
      background-color: #2a2a2a;
      border-radius: 4px;

      .summary_header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .summary_icon {
          font-size: 16px;
          margin-right: 8px;
        }

        .summary_title {
          font-size: 14px;
          font-weight: bold;
          margin-right: 8px;
        }

        .info_icon {
          font-size: 12px;
          color: #888;
        }


      }

      .summary_text {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
}
