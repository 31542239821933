@import "../../../App.scss";

.error_in_page_wrapper {
  width: 100%;
  height: 356px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .error_screen_img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    object-fit: cover;
    margin-bottom: 24px;
  }

  .error_screen_text {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    @include font_gt_walsheim;
    margin-bottom: 12px;
  }
  .try_again_cta {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .try_again_icon {
      margin-right: 8px;
    }
  }
}