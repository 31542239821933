@import "../../../App.scss";
.toast_msg_container {
  width: 100%;
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;

  .toast_wrapper {
    display: flex;
    flex-direction: column;
    min-width: 343px;
    padding: 9px 10px;
    border-radius: 12px;
   

    &.success {
      border: 1px solid #107F47;
      background-color: #F0FAE5;
      .msg_txt,.sub_text,.close_icon {
        color: #107F47;
      }
    }

    &.warn {
      border: 1px solid orange;
      .msg_txt,.sub_text,.close_icon {
        color: orange;
      }
    }

    &.error {
      border: 1px solid red;
      .msg_txt,.sub_text,.close_icon {
        color: red;
      }
    }

    .toast_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .msg_section {
        display: flex;
        align-items: center;
        .icon_section {
          display: flex;
          margin-right: 8px;
        }

        .msg_txt {
          font-size: 16px;
          line-height: 24px;
          @include font_gt_walsheim_bold;
        }
      }

      .close_icon {
        cursor: pointer;
        margin-left: 16px;
      }
    }


    .sub_text {
      font-size: 14px;
      line-height: 18px;
      margin-top: 2px;
    }
  }
}

