@import "../../../../App.scss"; // Import your base styles if needed

.empty_state_document {
  margin-top: 24px;

  .empty_state_msg {
    height: 200px;
    border-radius: 12px;
    @include flex_center;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
  }
}

.table_container {
  margin-top: 20px;
  min-height: 65vh;

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }

  .heading_area {
    width: 100%;
    display: flex;
    margin-bottom: 15px;

    .heading {
      @include font_gt_walsheim_bold;
      font-size: 16px;
      line-height: 28px;
      width: 100%;
    }

    .right_section {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .uploadLabel {
        width: 70px;
      }

      .upload_section {
        display: flex;
        @include font_gt_walsheim_bold;
        font-size: medium;
        padding-left: 10px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
        cursor: pointer;

        .upload_icon_light {
          background-image: url("../../../../assets/fonts/upload-icon-dark.svg");
          height: 13px;
          width: 18px;
          margin-right: 7px;
          margin-top: 3px;
        }

        .upload_icon_dark {
          background-image: url("../../../../assets/fonts/upload-icon-light.svg");
          height: 13px;
          width: 18px;
          margin-right: 7px;
          margin-top: 3px;
        }
      }
    }
  }

  .table_wrapper {
    overflow: auto;
    overflow-y: scroll;
    min-height: 508px;
    max-height: calc(100vh - 360px);

    table {
      table-layout: fixed;

      .tbl_header_wrapper {
        display: table-caption;
        position: sticky;
        top: -4px;
        z-index: 2;
      }

      .tbl_header {
        height: 64px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .header_cell {
          @include font_gt_walsheim_bold;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding: 0 20px;
          text-align: center;
          flex: 1;
          min-width: 260px;
          //   max-width: 222px;
          width: auto;
          cursor: default;

          span {
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          img {
            width: 16px;
            height: 16px;
          }

          &:first-child {
            position: sticky;
            left: -1px;
            z-index: 3;
            min-width: 260px;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }

      .tbl_data_row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        border-radius: 12px;
        font-size: 16px;
        line-height: 24px;

        .data_cell {
          display: flex;
          align-items: center;
          height: 64px;
          padding: 0 20px;
          flex: 1;
          font-size: 14px;
          line-height: 18px;
          min-width: 260px;
          gap: 10px;
          width: auto;
          @include font_gt_walsheim;
          border-right: 0 !important;
          text-align: left;

          &.pgi_cols {
            justify-content: space-between;

            .data_item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-right: 10px;
              margin-right: 10px;

              &:last-child {
                border-right: 0;
              }

              .data_value {
                @include font_gt_walsheim_bold;
              }

              .data_label {
                @include font_gt_walsheim;
              }
            }
          }

          &:first-child {
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            position: sticky;
            left: 0;
            border-radius: 12px 0 0 12px;
            min-width: 260px;
          }

          &:last-child {
            display: flex;
            align-items: center;
            border-radius: 0 12px 12px 0;
          }

          .file_name {
            @include ellipsis;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
            @include font_gt_walsheim_medium;
            cursor: pointer;
            max-width: 100%;
          }

          .download_icon {
            padding-right: 16px;
            margin-right: 16px;
            display: flex;
            cursor: pointer;

            img {
              width: 14px;
              height: 18px;
              flex-shrink: 0;
            }
          }

          .delete_icon {
            width: 16px;
            height: 18px;
            display: flex;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              flex-shrink: 0;
            }
          }
        }

        .emptyDataCell {
          display: flex;
          align-items: center;
          justify-content: left;
        }
      }

      .info_logo_outer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: #38539a;
        flex-shrink: 0;
      }

      .info_logo_inner {
        display: flex;
        justify-content: center;
        margin: auto;
        text-align: center;
        @include font_gt_walsheim_bold;
        font-size: 16px;
        line-height: 24px;
      }
    }

    table th:first-child {
      border-radius: 12px 0 0 12px;
    }

    table th:last-child {
      border-radius: 0 12px 12px 0;
    }
  }
}

.infoCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.infoRightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 58px);

  .labelInfo {
    @include font_gt_walsheim_bold;
    text-align: left;
    max-width: 95%;
    padding-bottom: 3px;
    @include ellipsis;
  }

  .labelInfoBottom {
    @include font_gt_walsheim;
    padding-top: 2px;
    @include ellipsis;
    max-width: 100%
  }
}

.trackerAward {
  @include font_gt_walsheim;
}

.revPercentOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include font_gt_walsheim;
  align-items: center;
  margin-left: 10px;
  border-radius: 4px;

  .revenueIndicator {
    // padding-left: 4px;
    padding: 2px 4px;
  }

  .revenuepercent {
    // padding-left: 2px;
    padding: 2px 4px;
  }
}

.awardIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  border-radius: 4px;

  .awardIconLeft {
    display: flex;
    align-items: center;
  }

  .awardIconRight {
    @include font_gt_walsheim_medium;
    white-space: nowrap;
  }
}

.viewmore_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 0;

  .viewmore_label {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    font-feature-settings:
      "clig" off,
      "liga" off;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.spinerStyling {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
}

.twoTimeAwardStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include font_gt_walsheim;
  align-items: center;
  margin-left: 10px;
  border-radius: 4px;
  padding-left: 4px;

  .noTrackerText {
    padding: 4px;
  }
}