@import "../../../../../App.scss";

.tag_container {
    display: inline-flex;
    align-items: center;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;
    margin-right: 16px;
    margin-bottom: 12px;
    font-feature-settings: 'liga' off, 'clig' off;
    height: 36px;
    cursor: pointer;

    .tag_label {
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim_medium;
    }

    .circleCheck_tag {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.small_tag_container {
    display: inline-flex;
    align-items: center;
    border-radius: 24px;
    padding: 4px 12px 4px 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    height: 36px;
    cursor: pointer;
    &:first-child {
        margin-left: 16px;
    }
    &:last-child {
        margin: 0;
    }
    .tag_label {
        margin-left: 4px;
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim;
    }

    .circleCheck_tag {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}