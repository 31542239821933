// Breakpoints
$breakpoint_sm_phone: 600px;
$breakpoint_sm: 768px;
$breakpoint_md: 992px;
$breakpoint_lg: 1200px;
$breakpoint_xl: 1440px;
$breakpoint_xxl: 1920px;

@mixin breakpoint($size) {
  @if $size==sm_phone {
    @media all and (min-width: $breakpoint_sm_phone) {
      @content;
    }
  } @else if $size==gtxs {
    @media all and (min-width: $breakpoint_sm) {
      @content;
    }
  }
  // // this is only for backward compatibility, use "gtxs"
  // // TBD - remove it
  // @else if $size==gts {
  //   @media all and (min-width: $breakpoint_sm) {
  //     @content;
  //   }
  // }

  @else if $size==lg {
    @media all and (min-width: $breakpoint_lg) {
      @content;
    }
  } @else if $size==md {
    @media all and (min-width: $breakpoint_md) {
      @content;
    }
  } @else if $size==xl {
    @media all and (min-width: $breakpoint_xl) {
      @content;
    }
  } @else if $size==xxl {
    @media all and (min-width: $breakpoint_xxl) {
      @content;
    }
  }
}
