@import "../../../../App.scss";

.annual_tbl_wrapper {
  border-radius: 12px;
  overflow: hidden;

  .annual_table {
    width: 100%;
    border-collapse: collapse;
    @include font_gt_walsheim;

    thead {
      .tbl_head {
        th {
          height: 50px;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          text-align: left;
          padding: 0 12px;
          width: 33.34%;
          vertical-align: middle;
          @include font_gt_walsheim_bold;

          &:nth-child(2) {
            text-align: center;
          }

          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }

    tbody {
      .tbl_row {
        height: 40px;

        td {
          padding: 0 12px;
          font-size: 16px;
          line-height: 24px;
          width: 33.34%;
          vertical-align: middle;

          &:first-child {
            text-align: left;
            @include font_gt_walsheim_medium;
          }

          &:nth-child(2) {
            text-align: center;
            @include font_gt_walsheim_bold;
            &.medium_font {
              @include font_gt_walsheim_medium;
            }
          }

          &:nth-child(3) {
            text-align: right;
          }
        }
      }

      .yoy_value {
        @include font_gt_walsheim;
      }
    }
  }
}