@import "../../../../App.scss";

.business_performance {
    .insight_content_wrapper {
        background: linear-gradient(0deg, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 0%, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 100%), var(--surface-surface-primary, #FFF);
        border-radius: 8px;
        padding: 16px;
        font-size: 16px; 
        line-height: 1.6;      
        p {
          margin-bottom: 12px;
          font-size: 1rem;
        }
      
        ul {
          margin: 0;
          padding-left: 20px;
          list-style: none;
      
          li {
            position: relative;
            padding-left: 16px;
            margin-bottom: 8px;
            font-size: 16px; 
            line-height: 1.6;
      
            &::before {
              content: "•";
              position: absolute;
              left: 0;
              line-height: 1.6;
            }
          }
        }
      
        .insight_item {
          margin-bottom: 8px;
      
          &:last-child {
            margin-bottom: 0;
          }
      
          .highlight {
            font-weight: bold;
          }
        }
      
        .updated_date {
          font-size: 0.8rem;
          color: #888; 
          text-align: right;
          margin-top: 12px;
        }
      }
      
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;

        .title {
            font-size: 1.5rem;
            flex-grow: 1;
        }

        .playbook_link {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 16px;
            text-decoration: none;
            cursor: pointer;

            .playbook_text {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -5px;
                    height: 2px;
                }
            }

            .playbook_icon {
                margin-right: 8px;
            }
        }
    }

    .last_closed_month_wrapper {
        margin-bottom: 15px;
        .last_closed_month {
            display: block;
            font-size: 14px;
            line-height: 16px;
            // margin-bottom: 30px;
        }
    }

    .ai_insight_wrapper {
        padding: 12px;
        border-radius: 12px;
        height: 48px;
        width: 100%;
    }

    .insight_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        height: 56px;
        padding: 16px;
        gap: 8px;

        .ai_insight {
            font-size: 1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 4px;

            span[role="img"] {
                margin-right: 8px;
            }
        }

        .update_section {
            display: flex;
            align-items: center;

            .update_text {
                font-size: 0.9rem;
            }

            .expand_icon {
                align-items: center;
                margin-left: 5px;
                height: 18px;
                width: 18px;
            }
        }
    }

    .outerPillContainer {
        display: flex;
        flex-direction: row;
        padding-top: 24px;
    }

    .pillContainer {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        white-space: nowrap;
        margin-top: 24px;
        gap: 4px;

        .separator_icon {
            align-self: flex-start;
            height: 36px;
        }
    }
}