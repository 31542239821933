@import "../../../../App.scss";

.outer_pill_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.pill_container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  padding-right: 16px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #ccc;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #f1f1f1;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

}

.separator_icon {
  align-self: flex-start;
  height: 36px;
  margin-right: 16px;
}