@import "../../../../App.scss";

.abo_bg_loading_container {
  width: 100%;
  margin-bottom: 35px;
  .heading {
    @include font_gt_walsheim_bold;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    width: 70%;
    margin-bottom: 10px;
  }
  .sub_heading {
    height: 44px;
    width: 100%;
    border-radius: var(--size-small, 12px);
    margin-bottom: 16px;
  }
  .details_loader {
    width: 100%;
    height: 100px;
    border-radius: var(--size-small, 12px);
    margin-bottom: 24px;
  }
}
