@import "../../../App.scss";
.ap_view_profile_container {
  padding: 24px 32px;
  flex: 1;
  .flex_wrapper {
    display: flex;
    align-items: flex-start;
    .content_wrapper {
      height: 100%;
      border-radius: 12px;
      width: calc(100% - 181px);
      @include breakpoint(lg) {
        width: calc(100% - 224px);
      }
      .inner_wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .ap_info_title_container {
          width: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ap_page_title {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.28px;
            text-transform: uppercase;
          }
          .ap_dropdown_wrapper {
            position: relative;
            width: 343px;
            right: 0;
            z-index: 2;
          }
        }
        .bottom_line {
          margin-bottom: 24px;
        }
      }
    }
  }
  @include breakpoint(lg) {
    padding: 24px 48px;
  }
}

.active_section {
  display: block;
  margin-bottom: 200px;
}

.hidden_section {
  display: none;
}
