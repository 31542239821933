@import "../../App.scss";
.myaccount_Container {
  padding: 32px;
  padding: 16px 48px 0;
  width: 100%;
  height: 100%;
  .section_title_head {
    font-size: 32px;
    @include font_gt_walsheim_bold;
    line-height: 36px;
    margin-bottom: 7px;
    .info_icon{
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .btn_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .customize_btn {
      display: flex;
      width: auto;
      height: 36px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 2px solid;
      cursor: pointer;
      .setting_icon {
        margin-right: 8px;
      }
      .label {
        font-size: 16px;
        @include font_gt_walsheim_bold;
        line-height: 24px;
      }
    }
	  .view_btn {
		display: flex;
		width: auto;
		height: 36px;
		padding: 8px 16px;
		justify-content: center;
		align-items: center;
		border-radius: 100px;
		border: 2px solid;
		cursor: pointer;
		@include ellipsis;
		max-width: 135px;

		.label {
			font-size: 16px;
			@include font_gt_walsheim_bold;
			line-height: 24px;
			@include ellipsis;
		    max-width: 135px;
		  }
	  }
  }
  .sub_heading {
    @include font_gt_walsheim_bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .drawerContent {
    margin-top: 24px;
    .subheading {
      font-weight: 700;
      font-family: "gt-walsheim-bold", sans-serif;
      size: 16px;
      line-height: 24px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      font-family: "gt-walsheim-regular", sans-serif;
    }
  }
  @include breakpoint(lg) {
    padding: 16px 48px;
  }
}
