@import "../../../App.scss";

.tag_dropdown_wrapper {
  position: relative;
  width: 100%;

  .tag_input_wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .input_wrapper {
      @include font_gt_walsheim;
      display: flex;
      align-items: center;
      position: relative;
      width: 343px;
      margin-right: 16px;
      .tag_input {
        width: 343px;
        border-radius: 12px;
        height: 56px;
        padding: 16px;
        padding-right: 36px;
        font-size: 16px;
        line-height: 24px;
        outline: none !important;
      }
      .arrow_icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.3s ease-in-out;
        cursor: pointer;
        &.rotate {
          transform: rotate(180deg);
          top: 35%;
        }
      }
  
      .tag_count {
        font-size: 0.875rem;
        color: #888;
      }
    }
  }
  .add_tag {
    margin-right: 12px;
  }

  .dropdown_menu {
    position: absolute;
    top: 66px;
    width: 343px;
    //transition: max-height 1s ease-out;
    border-radius: 12px;
    transition: max-height 0.5s ease-in-out;
    z-index: 1;
    height: 224px;
    overflow-y: auto;

    .dropdown_item {
      padding: 12px;
      height: 44px;
      cursor: pointer;
    }

    .add_tag_btn {
      display: flex;
      align-items: center;
      padding: 12px;
      height: 44px;
      cursor: pointer;
      font-weight: bold;
      .plus_icon {
        margin-right: 4px;
      }
    }
  }


  .tab_container {
    display: flex;
    flex-wrap: wrap;
  }

  .error_msg {
    color: red;
    margin-bottom: 16px;
    font-size: 14px;
  }
}
