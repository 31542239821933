@import "../../../App.scss";

.empty_state_document {
  .empty_state_msg {
    height: 200px;
    border-radius: 12px;
    @include flex_center;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
  }
}

.table_container {
  margin-top: 20px;
  min-height: 25%;
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  .heading_area {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    .heading {
      @include font_gt_walsheim_bold;
      font-size: 16px;
      line-height: 28px;
      width: 100%;
    }
    .right_section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .uploadLabel {
        width: 70px;
      }
      .upload_section {
        display: flex;
        @include font_gt_walsheim_bold;
        font-size: medium;
        padding-left: 10px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
        cursor: pointer;
        .upload_icon_light {
          background-image: url("../../../assets/fonts/upload-icon-dark.svg");
          height: 13px;
          width: 18px;
          margin-right: 7px;
          margin-top: 3px;
        }
        .upload_icon_dark {
          background-image: url("../../../assets/fonts/upload-icon-light.svg");
          height: 13px;
          width: 18px;
          margin-right: 7px;
          margin-top: 3px;
        }
      }
    }
  }
  .table_wrapper {
    width: 100%;
    overflow: auto;
    .table {
      width: 100%;
      table-layout: fixed; 
      .tbl_header_wrapper {
        // display: table-caption;
      }
      .tbl_header {
        height: 52px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .header_cell {
          @include font_gt_walsheim_bold;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding: 0 12px;
          text-align: left;
          cursor: pointer;
          @include breakpoint(md){
            font-size: 16px;
            line-height: 24px;
            padding: 0 20px;
          }
          span {
            margin-right: 10px;
          }
          img {
            width: 16px;
            height: 16px;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
      .tbl_data_row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        border-radius: 12px;
        font-size: 16px;
        line-height: 24px;
        .data_cell {
          display: flex;
          align-items: center;
          height: 60px;
          padding: 0 12px;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          @include font_gt_walsheim;
          @include breakpoint(md){
            font-size: 16px;
            line-height: 24px;
            padding: 0 20px;
          }
          &:first-child {
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            border-radius: 12px 0 0 12px;
          }
          &:nth-child(2){
            border-left: 0;
          }
          &:last-child {
            border-left: 0;
            border-radius: 0 12px 12px 0;
            display: flex;
            align-items: center;
          }
          .file_name {
            @include ellipsis;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
            @include font_gt_walsheim_medium;
            cursor: pointer;
            max-width: 100%;
          }
          .type_name {
            @include ellipsis;
            max-width: 100%;
            cursor: auto;
          }
          .download_icon {
            padding-right: 16px;
            margin-right: 16px;
            display: flex;
            cursor: pointer;
            img {
              width: 14px;
              height: 18px;
              flex-shrink: 0;
            }
          }
          .delete_icon {
            width: 16px;
            height: 18px;
            display: flex;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              flex-shrink: 0;
            }
          }
        }
      }

      .header_cell:first-child {
        flex: 50% 1 1;
        min-width: 50%;
      }

      .header_cell:nth-child(2) {
        flex: 30% 1 1;
        min-width: 30%;
      }

      .header_cell:nth-child(3) {
        flex: 20% 1 1;
        min-width: 20%;
      }

      .header_cell:nth-child(4) {

      }

      // for data
      .data_cell:first-child {
        flex: 50% 1 1;
        min-width: 50%;
      }

      .data_cell:nth-child(2) {
        flex: 30% 1 1;
        min-width: 30%;
      }

      .data_cell:nth-child(3) {
        flex: 20% 1 1;
        min-width: 20%;
      }

      .data_cell:nth-child(4) {
        // width: 15%;
        // min-width: 15%;
      }
    }
    table th:first-child {
      border-radius: 12px 0 0 12px;
    }
    table th:last-child {
      border-radius: 0 12px 12px 0;
    }
    // Succession Table 
    &.succession_table {
      .table {  
        .header_cell:first-child {
          flex: 40% 1 1;
          min-width: 40%;
        }
  
        .header_cell:nth-child(2) {
          flex: 20% 1 1;
          min-width: 20%;
        }
  
        .header_cell:nth-child(3) {
          flex: 20% 1 1;
          min-width: 20%;
        }
  
        .header_cell:nth-child(4) {
          flex: 20% 1 1;
          min-width: 20%;
        }
  
        // for data
        .data_cell:first-child {
          flex: 40% 1 1;
          min-width: 40%;
        }
  
        .data_cell:nth-child(2) {
          flex: 20% 1 1;
          min-width: 20%;
        }
  
        .data_cell:nth-child(3) {
          flex: 20% 1 1;
          min-width: 20%;
        }
  
        .data_cell:nth-child(4) {
          flex: 20% 1 1;
          min-width: 20%;
        }
      }
    }
  }
}

.viewmore_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 0;
  .viewmore_label {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    font-feature-settings:
      "clig" off,
      "liga" off;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}
