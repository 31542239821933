@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
@mixin ellipsis_no_cursor {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin ellipsis_two_liner {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-all;
}
@mixin ellipsis_three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//  border bottom with Round Corners
@mixin underline-rounded($color: #000, $height: 2px, $radius: 1px) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $color;
    border-radius: $radius;
  }
}


