@import "../../../../../../App.scss";

.tile_main_wrapper {
  height: 100%;
  cursor: pointer;

  .tile_upper_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45%;

    .tile_header {
      @include font_gt_walsheim_medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .tile_body {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 24px;
      //font-weight: bold;
      // gap: 16px;

      .tile_value {
        @include font_gt_walsheim_bold;
        font-size: 24px;
        font-weight: 700;
        margin-right: 16px;
      }

      .tile_change_value_wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .change_arrow {
          font-size: 12px;
        }

        .change_value {
          @include font_gt_walsheim_bold;
          font-size: 21px;
        }

        .change_unit {
          @include font_gt_walsheim;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
  }

  .tile_lower_section {
    font-size: 0.9rem;
    align-items: first baseline;
    margin-top: 12px;

    .lower_section_title {
      @include font_gt_walsheim_medium;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .separator_line {
    border: none;
    margin: 0;
  }
}