@import "../../../App.scss";
.benchmark_wrapper {
  .benchmark_title_section {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    @include font_gt_walsheim_medium;
    font-size: 14px;
    font-weight: 500;
    .info_icon {
      cursor: pointer;
    }
    .bold_text {
      font-weight: bold; // Make text bold
    }
  }
  .benchmark {
    width: 100%;
    // max-width: 400px;
    @include font_gt_walsheim;
    .benchmark_track {
      position: relative;
      height: 20px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      .benchmark_sections {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        .benchmark_section {
          height: 100%;
          position: absolute;
          z-index: 2;
          cursor: pointer;
        }
      }
      .benchmark_marker {
        position: absolute;
        top: -11px;
        transform: translateX(-50%);
        transition: left 0.3s ease;
        z-index: 2;
        .benchmark_pin {
          width: 15px;
          height: auto;
        }
      }
    }
    .benchmark_labels {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 18px;
      position: relative;
      @include font_gt_walsheim;
      :nth-child(1){
        position: relative;
        left: -5px;
      }
      .mid_values {
        position: absolute;
      }
    }
  }
}
