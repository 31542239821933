@import "../../../App.scss";

.login_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login_image_section {
  }
  .login_message_section {
    margin: 24px 0px;
    .login_continue_text {
      text-align: center;
      font-size: 49px;
      @include font_gt_walsheim_bold;
      line-height: 56px;
      margin-bottom: 10px;
    }
    .sign_in_text {
      text-align: center;
      font-size: 18px;
      @include font_gt_walsheim;
      line-height: 21px;
    }
  }
  .btn_area {
  }
  .error_msg {
    margin-top: 15px;
    font-size: medium;
    color: red;
  }
}