@import "../../../../../App.scss";

.card_profile {
  width: 100%;
  height: 109px;
  margin-bottom: 24px;
  border-radius: 12px;
}

.card_details {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
