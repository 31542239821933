@import "../../../styles/all.scss";

.footer_container {
  z-index: 7;
  padding: 24px 24px 24px;
  margin-top: auto;

  .footer_content {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
    .content {
      margin-right: 20px;
    }
    .links {
      margin-top: 8px;
      cursor: pointer;
      a {
        text-decoration: none;
        margin: 0 4px;
        @include font_gt_walsheim;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
