.content_wrapper strong {
  font-family: "gt-walsheim-bold", sans-serif;
  font-weight: 700;
}
.content_wrapper em {
  font-style: italic;
}
.content_wrapper ol {
  list-style: auto;
  margin-left: 20px;
}
.content_wrapper ul {
  list-style: disc;
  margin-left: 20px;
}
