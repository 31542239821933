@import "../../../App.scss";

.upload_container {
  padding: 50px 16px 24px;

  .modal_title {
    font-size: 21px;
    line-height: 28px;
    @include font_gt_walsheim_bold;
    margin-bottom: 24px;
  }

  .upload_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding: 32px;
    flex-shrink: 0;
    border-radius: 12px;
    height: calc(100% - 48px);

    /* Custom dashed border with increased stroke length */
    position: relative;
    padding: 20px;
    background: none;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      padding: 32px;
      pointer-events: none;
    }

    .upload_content {
      text-align: center;
      font-size: 21px;
      line-height: 16px;
      @include font_gt_walsheim_medium;

      .icon_wrapper {
        margin-bottom: 24px;
      }

      .upload_text {
        cursor: pointer;
        transition: background-color 0.3s;
        margin-bottom: 16px;
        font-size: 21px;

        &:last-child {
          margin-bottom: 0;
          text-decoration: underline;
        }
      }
    }
  }

  .uploading_state {
    // TODO;
  }
}
