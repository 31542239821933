@import "../../../App.scss";

.report_builder_wrapper {  
}

.initial_text {
  font-size: 32px;
  line-height: 36px;
  @include font_gt_walsheim_bold;
  margin-bottom: 12px;
}