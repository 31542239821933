@import "../../../App.scss";

.kpi_coming_soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 203px;
  padding: 12px;

  .kpi_icon {
    width: 170px;
    margin-bottom: 10px;
  }
  
  .kpi_text {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }
}
