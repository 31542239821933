@import "../../../App.scss";

.confirmation_popup_container {
  padding: 20px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title_container {
    text-align: center;
    font-size: 21px;
    line-height: 28px;
    @include font_gt_walsheim_bold;
    margin-top: 20px;
    // margin-bottom: 24px;
    padding-bottom: 4px;
  }
  .message_container{
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
    // margin-bottom: 24px;
  }
  .actionButton_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
}
