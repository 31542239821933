@import "../../../../../App.scss";

.summary_section {
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 tiles for large screens
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // 2 tiles for tablets
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; // 1 tile for mobile
  }
}

.tile_container {
  padding: 12px;
  border-radius: 12px;
  height: 203px; // Fixed height
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}