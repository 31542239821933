@import "../../App.scss";

.hatv_main_container {

  .hatv_outer_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 124px;
    .landing_page {
      width: 100%;

      @include breakpoint(md) {
        width: 800px;
      }
    }
  }

}