@import "../../../../../App.scss";

.ap_background_container {
  width: 100%;
  position: relative;
  .heading_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .heading {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      width: 70%;
    }
    .right_section {
      display: flex;
      justify-content: flex-end;
      .edit_section {
        display: flex;
        @include font_gt_walsheim_bold;
        font-size: small;
        padding-left: 10px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
        cursor: pointer;
        .edit_icon_light {
          background-image: url("../../../../../assets/fonts/edit-icon-light.svg");
          height: 15px;
          width: 14px;
          margin-right: 7px;
        }
        .edit_icon_dark {
          background-image: url("../../../../../assets/fonts/edit-icon-dark.svg");
          height: 15px;
          width: 14px;
          margin-right: 7px;
        }
      }
    }
  }
  .sub_heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .last_modified {
    padding-top: 10px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .details_wrapper {
    .item_wrapper {
      margin-bottom: 40px;
      .item_name {
        @include font_gt_walsheim_bold;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .item_value {
        line-height: normal;
        width: 100%;
      }
    }
  }
  .btn_wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 290px;
    margin-left: auto;
  }
  @include breakpoint(lg) {
    width: 75%;
    &.full_width {
      width: 100%;
    }
  }
}
