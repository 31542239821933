@import "../../../../../../App.scss";

.search_bar_container {
  display: flex;
  align-items: center;
  width: 274px;
  border-radius: 25px;
  padding: 12px;
  height: 48px;
  position: relative;
  .search_icon {
    cursor: pointer;
  }

  .search_input {
    flex: 1;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px;
    @include ellipsis;
    cursor: text;
    @include font_gt_walsheim_medium;
  }
  .search_dropdown_container {
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
  }
}
