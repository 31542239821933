@import "../../../../App.scss";

.my_accounts_loading_container {
  width: 100%;
  margin-bottom: 35px;
  .heading {
    @include font_gt_walsheim_bold;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    width: 70%;
    margin-bottom: 10px;
  }
  .table_heading {
    height: 44px;
    width: 100%;
    border-radius: var(--size-small, 12px);
    margin-bottom: 12px;
  }
  .btn_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .customize_btn_large {
      width: 173px;
      height: 36px;
      border-radius: 100px;
      margin-right: 8px;
    }
    .customize_btn_small {
      width: 89px;
      height: 36px;
      border-radius: 100px;
      margin-right: 8px;
    }
    .customize_btn_medium {
      width: 135px;
      height: 36px;
      border-radius: 100px;
    }
  }
  .table_row {
    width: 100%;
    height: 64px;
    border-radius: var(--size-small, 12px);
    margin-bottom: 12px;
  }
}
