@import "../../../App.scss";

.tag_container {
  display: inline-flex;
  align-items: center;
  border-radius: 24px;
  padding: 4px 8px 4px 12px;
  margin: 0 4px 12px;
  font-feature-settings: 'liga' off, 'clig' off;
  height: 32px;

  .tag_label {
    font-size: 16px;
    line-height: 24px;
    @include font_gt_walsheim_medium;
  }
  .delete_tag {
    margin-left: 4px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
