// // /* Tooltip.module.scss */
.tooltip_wrapper {
  position: relative;
  display: inline-block; 

  .tooltip {
    visibility: hidden;
    position: absolute;
    transform: translateX(-50%);
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    white-space: nowrap; 
    z-index: 10;
    opacity: 0; 
    transition: opacity 0.3s ease, visibility 0.3s ease; 
    background-color: rgba(0, 0, 0, 0.8); 
    color: #fff; 
    width: max-content; 
    max-width: 254px; 

    &::after {
      content: "";
      position: absolute;
      top: 100%; 
      left: 10%; 
      transform: translateX(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent; /* Arrow color */
    }
  }

  .tooltip_text {
    text-align: left; 
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: normal; 
  }

  /* Show tooltip on hover */
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
