@import "../../../App.scss";

.report_wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 48px 0 48px;

  .report_header_wrapper {
    display: flex;
    justify-content: space-between;

    .heading_wrapper {
      margin-bottom: 32px;

      .report_heading {
        font-size: 32px;
        padding-bottom: 15px;
        @include font_gt_walsheim_bold;
      }

      .report_subheading {
        font-size: 18px;
        @include font_gt_walsheim;
      }
    }

    .nav_btn_wrapper {
      display: flex;
      @include font_gt_walsheim;
      font-size: 16px;
      line-height: 24px;

      Button {
        padding: 0 32px;
        min-width: auto;
        height: 36px;

        &:first-child {
          margin-right: 24px;
          min-width: 135px;
        }
      }
    }
  }

  .card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    cursor: pointer;

    .card {
      padding: 16px;
      border-radius: 12px;
      flex: 1 1 calc(33.33% - 16px);
      height: 169px;
      box-sizing: border-box;

      .card_heading {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 21px;
        @include font_gt_walsheim_bold;
      }

      .card_content {
        font-size: 14px;
        @include font_gt_walsheim;
        line-height: 18px;
      }
    }
  }

  .report_builder_section {
    width: 438px;
    height: 56px;
  }
}