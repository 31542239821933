@import "../../../App.scss";

.header_container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;

  .top_bar_wrapper {
    height: 60px;
    padding: 0 48px;
    display: flex;
    align-items: center;
    // @include font_gt_walsheim_bold;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .navlist_left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // visibility: hidden;
      .nav_item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        cursor: pointer;

        .ai_flower_logo {
          margin-right: 2px;
        }

        .label {
          margin-right: 4px;
          font-size: 14px;
          line-height: 18px;
          @include font_gt_walsheim;

          @include breakpoint(lg) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .icon_wrapper {
          width: 16px;
          height: 16px;
        }

        @include breakpoint(lg) {
          margin-right: 32px;
        }
      }
    }

    .navlist_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .toggle_switch_wrapper {
        margin-right: 16px;

        @include breakpoint(md) {
          margin-right: 32px;
        }
      }

      .lang_select_wrapper {
        margin-right: 16px;

        @include breakpoint(md) {
          margin-right: 32px;
        }
      }

      .right_section_container {
        display: flex;
        align-items: center;
        justify-content: center;

        .logout_btn {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;

          .logout_txt {
            display: none;

            @include breakpoint(lg) {
              display: inline-flex;
              margin-right: 8px;
              font-size: 16px;
              line-height: 24px;
            }
          }

          .logout_img {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .main_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    padding: 0 48px;

    .leftSection {
      display: flex;
      align-items: center;
      gap: 20px;

      .amway_logo {
        width: 46px;
        height: 48px;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint(lg) {
          width: 110px;
          height: 34px;
        }
      }

      .navbar {
        .navlist_left {
          .nav_item {
            color: #2c2c2c;
            font-size: 18px;
            line-height: 21px;
            @include font_gt_walsheim_medium;
          }
        }
      }
    }

    .navigation {
      display: flex;
      gap: 20px;
    }

    .activeLink {
      font-weight: bold;
    }

    .right_section {
      display: flex;
      align-items: center;
    }

    .search_bar {
      // margin-right: 12px;
    }

    .notification_icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // TODO
      display: none;

      .bell_icon {
        width: 18px;
        height: 20px;
        flex-shrink: 0;
      }

      .notification_count {
        position: absolute;
        top: -5%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        padding: 0 4px;
        color: white;
        min-width: 20px;
        height: 20px;
        flex-shrink: 0;
        font-size: 14px;
        line-height: 18px;
        @include font_gt_walsheim_bold;
      }
    }
  }

}