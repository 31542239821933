@import "../../../App.scss";
.violation_details_container {
  .backButton {
    background: none;
    color: #888;
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    &:hover {
      color: #fff;
    }
  }

  .red {
    color: #ff6b6b;
  }
  .yellow {
    color: #f3d34a;
  }
    .violation_details_title {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;
      margin-bottom: 40px;
      cursor: pointer;
    }
    .violation_response_textline_wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
  
      .ai_logo {
        margin-right: 16px;
      }
  
      .textline {
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim;
      }
    }
}
