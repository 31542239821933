@import "../../../../App.scss";

.empty_state {
  .empty_state_msg {
    min-height: 200px;
    border-radius: 12px;
    @include flex_center;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
  }
}

.legAnalysis_wrapper {
  .heading_area {
    width: 100%;
    //display: flex;
    //justify-content: space-between;
    margin-bottom: 20px;

    .heading {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      line-height: 28px;
      width: 70%;
      margin-bottom: 8px;
      &.small_heading {
        font-size: 18px;
        line-height: 21px;
      }
    }
    .sub_heading {
      @include font_gt_walsheim;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      //margin-bottom: 15px;
    }
  }

  .legAnalysis_section {
    margin-bottom: 24px;
  }

  .btns_wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 290px;
    margin-left: auto;
  }
}