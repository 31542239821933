@import "../../../../App.scss";

.search_loading_container {
  width: 100%;
  .card_loader {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .profile {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 16px;
    }
    .content_loader {
      width: calc(100% - 100px);
      height: 50px;
      border-radius: var(--size-small, 12px);
      margin-right: 12px;
      .first_line {
        width: 100%;
        height: 28px;
        margin-bottom: 4px;
        border-radius: 8px;
      }
      .second_line{
        width: 75%;
        height: 18px;
        border-radius: 8px;
      }
    }
    .profile_nav_btn {
      margin-left: auto;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: contain;
    }

  }
}
