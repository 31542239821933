// Variable name for themes color

$theme_bg_color: #000000;
$secondary_theme_bg: #2c2c2c;
$text_color: #d8d8d9;
$text_positive: #b3d67b;
$text_negative: #F16A81;
$header_bg_color: #525050;
$bg_color: #fff;
$dark_charcoal: #282828;
$profile_fg_color: #d8d8d9;
$grey_color_2: #aeaeb2;
$card_text_color: #d8d8d9;
$card_tag_primary_bg: #1c1c1e;
$card_tag_non_primary_bg: #1c1c1e;
$card_tag_primary_color: #99b2f5;
$card_tag_non_primary_color: #77cbbf;
$card_section_lbl: #d8d8d9;
$abo_field_title_color: #aeaeb2;
$abo_field_data_color: #d8d8d9;
$text_dim_gray: #aeaeb2;
$text_gray_1: #8E8E93;
$border_dim_gray: #1c1c1e;
$theme_purple: #99b2f5;
$selected_state: #d8d8d9;
$abo_text_area_color: #aeaeb2;

$dropdown_btn_color: #d8d8d9;
$down_arrow_url: url("../assets/images/DarkTheme/dropdown_down_arrow.svg");
$dropdown_menu_border: #d8d8d9;
$dropdown_menu_bg_color: #1c1c1e;
$dropdown_menu_fg_color: #d8d8d9;
$selected_item_fg_color: #99b2f5;
$border_dark_grey: #636366;
$dark-charcoal: #282828;
$color_light_gray_var_one: #d6d6d6;
$color_light_gray_var_two: #d9d9d9;
$color_purple: #5969d6;
$color_dark_gray: #5e5e5e;
$color_red: #d7354d;
$color_white: #ffffff;
$medium_dark_gray: #636363;
$button_disable_color: #8e8e93;
$dim_black: #282828;
$right_arrow: url("../assets/images/DarkTheme/right_arrow.svg");
$amwayLogoDesk: url("../assets/images/Amway_logo_d.svg");
$amwayLogoTab: url("../assets/images/DarkTheme/amway_logo_A.svg");
$success_green: #B3D67B;
$color_red_faint: #F16A81;
// Classes for themes

.layout_container {
  background-color: $theme_bg_color;
}

a {
  color: $text_color;
}

.animate {
  animation: shimmer 2s infinite linear;
  background: var(--skeleton-loading, linear-gradient(90deg, #1C1C1E 0.19%, #3A3A3C 50.19%, #1C1C1E 100%));
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.dropdown_container {
  .dropdown_button {
    color: $text_color
  }

  .up_arrow {
    background-image: $down_arrow_url;
    transform: rotate(180deg);
  }

  .down_arrow {
    background-image: $down_arrow_url;
  }

  .dropdown_menu {
    // border: $dropdown_menu_border;
    border: 2px solid none;
    background-color: $dark_charcoal;
    color: $dropdown_menu_fg_color;
    box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.84);

    .dropdown_item {
      &:hover {
        background-color: $card_tag_primary_bg;
      }
    }

    .dropdown_selected_item {
      // background-color: $dark_charcoal;
      color: $selected_item_fg_color;
      @include font_gt_walsheim_bold;
    }
  }
}

.tag_dropdown_wrapper {
  .tag_input {
    border: 2px solid $medium_dark_gray;
    background-color: $dropdown_menu_bg_color;
    color: $dropdown_menu_fg_color;

    &:focus {
      // border: 2px solid $theme_purple;
      border: 2px solid $theme_purple;
    }
  }

  .dropdown_menu {
    // border: 2px solid #5969D6;
    border: 2px solid $theme_purple;
    background-color: $dropdown_menu_bg_color;
    color: $dropdown_menu_fg_color;

    .dropdown_item {
      background-color: $dark_charcoal;
      color: $text_color;

      &.checked {
        background-color: $dropdown_menu_bg_color;
      }

      &:hover {
        background-color: $dropdown_menu_bg_color;
      }
    }
  }
}

.hatv_header_wrapper {
  border-bottom: 1px solid $dark_charcoal;
}

// Header Theme
.header_container {
  background-color: $theme_bg_color;
  color: $grey_color_2;

  .top_bar_wrapper {
    border-bottom: 1px solid $dark_charcoal;

    .lang_switch_select {
      color: $text_color;
    }

    .theme_switch_container {
      background-color: $dark_charcoal;
      border: 1px solid $border_dim_gray;

      .toggle_switch_btn {
        background-color: $dark_charcoal;
      }
    }
  }

  .main_header_wrapper {
    border-bottom: 1px solid $dark_charcoal;
    border-bottom: 1px solid $border_dim_gray;

    .right_section_container {
      .logout_btn {
        background-color: $theme_purple;

        .logout_txt {
          color: $text_color;
        }
      }
    }

    .amway_logo {
      background-image: $amwayLogoTab;

      @include breakpoint(lg) {
        background-image: $amwayLogoDesk;
      }
    }

  }

  .notification_count {
    background: $theme_purple;
    color: $theme_bg_color;
  }

  .label {
    color: $text_dim_gray;
  }
}

.search_bar_container {
  background-color: $dark_charcoal;

  .search_input {
    color: $text_dim_gray;

    &::placeholder {
      color: $grey_color_2;
    }
  }
}

// Footer
.footer_container {
  background-color: $theme_bg_color;
  border-top: 1px solid $dark_charcoal;
}

// Profile subheader
.profile_wrapper {
  color: $profile_fg_color;
  background-color: $dim_black;
  .profile_image_wrapper{
    color: $theme_bg_color;
    background-color: $theme_purple;
  }

  .abo_number {
    color: $grey_color_2;
  }

  .country {
    color: $grey_color_2;
  }

  .field_title_wrapper {
    color: $abo_field_title_color;
  }
  .account_name_wrapper {
    .account_name {
      color: $abo_field_title_color;
    }
    .account_name_value {
      color: $abo_field_data_color;
    }
  }

  .field_data_wrapper {
    color: $abo_field_data_color;
  }
}

.ap_view_profile_wrapper {
  color: $profile_fg_color;
  background-color: $dim_black;

  .field_title_wrapper {
    color: $abo_field_title_color;
  }

  .field_data_wrapper {
    color: $abo_field_data_color;
  }
}

// Side Pannel
.sidebar_container {
  .section_title {
    color: $theme_purple;
  }

  .link_item {
    &:hover {
      background-color: $border_dim_gray;
    }

    &.disabled_tab {
      color: $text_gray_1;
    }
  }

  .selected_link {
    background-color: $border_dim_gray;
    @include font_gt_walsheim_medium;
    border-left: 4px solid $theme_purple;
  }
}

.content_inner {
  .content_inner_label {
    color: $card_section_lbl;
  }

  .view_more_text {
    color: $card_section_lbl;
  }
}

.contact_card_container {
  .contact_card {
    background-color: $dark_charcoal;
    border: 1px solid $theme_bg_color;

    .card_profile {
      border-bottom: 2px solid $theme_bg_color;
    }
  }
}

.contact_profile_info {
  .card_text {
    color: $card_text_color;
  }
}

.card_info {
  .card_text_info_header {
    color: $grey_color_2;
  }

  .card_text_info {
    color: $card_text_color;
  }
}

.card_tag_container_primary {
  background-color: $card_tag_primary_bg;

  .card_tag {
    color: $card_tag_primary_color;
  }
}

.card_tag_container_non_primary {
  background-color: $card_tag_non_primary_bg;

  .card_tag {
    color: $card_tag_non_primary_color;
  }
}

.abo_bg_container {
  .item_name {
    color: $profile_fg_color;
  }

  .grey_text {
    color: $grey_color_2;
  }
}

.text_area_wrapper {
  .input_wrapper {
    border: 1px solid $border_dark_grey;

    .lbl {
      color: $profile_fg_color;
      background-color: $theme_bg_color;

      &.on_focus {
        background-color: transparent;
      }
    }

    .text_area {
      background-color: $theme_bg_color;
      color: $abo_text_area_color;

      &:focus {
        border: 2px solid $theme_purple  !important;
        background-color: $dark_charcoal;
      }
    }
  }
}

// Buttons stylings
.unfilled {
  background-color: $dim_black;
  border: 2px solid $text_color;
  color: $text_color;
}

.filled {
  background-color: $text_color;
  color: $dim_black;
}

.disabled {
  background-color: $color_light_gray_var_two;
  color: $color_dark_gray;
}

.button {
  &.disabled {
    background-color: $dark_charcoal;
    color: $button_disable_color;
  }

  .spinner {
    border: 4px solid $color_light_gray_var_one;
    border-top: 4px solid $color_purple;
  }
}

// common spinner
.spinner {
  border: 4px solid $color_light_gray_var_one;
  border-top: 4px solid $color_purple;
}

.goals_wrapper {
  .sub_heading {
    color: $grey_color_2;
  }

  .sub_heading_with_goals {
    color: $card_text_color;
  }

  .goals_section {
    .goals {
      color: $grey_color_2;
    }

    .text_area_wrapper {
      .lbl {
        color: $grey_color_2;
        // background-color: $dark_charcoal;
      }

      .text_area {
        background-color: $dark_charcoal;
      }
    }
  }
}

.section_sub_heading {
  color: $card_text_color;
}

// Tables
.table_container {
  .tbl_header {
    .header_cell {
      background-color: $dark_charcoal;
      border-right: 1px solid $theme_bg_color;

      img {
        width: 16px;
        height: 16px;
        display: none;
      }
    }

    .header_cell:hover {
      background-color: #494949;
    }

    .header_cell:hover img {
      display: flex;
    }
  }

  .tbl_data_row {
    border: 0;

    .data_cell {
      border-right: 1px solid $theme_bg_color;

      .download_icon {
        border-right: 1px solid $theme_bg_color;
      }
    }
  }
}

.table_sub_title {
  color: $abo_text_area_color;
}

.table_no_data {
  border: 1px solid $dark-charcoal;
  background: $dark-charcoal;
  color: $grey_color_2;
}

// Modal Theme
.modal_container {
  .modal_content {
    background-color: $border_dim_gray;
    // box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }

  .modal_backdrop {
    // background-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
  }
}

// Upload Component
.upload_container {
  .upload_wrapper {
    &:before {
      background: repeating-linear-gradient(to right,
          $border_dark_grey 0,
          $border_dark_grey 15px,
          transparent 15px,
          transparent 30px) 2px 0,
        repeating-linear-gradient(to bottom,
          $border_dark_grey 0,
          $border_dark_grey 15px,
          transparent 15px,
          transparent 30px) 0 2px,
        repeating-linear-gradient(to right,
          $border_dark_grey 0,
          $border_dark_grey 15px,
          transparent 15px,
          transparent 30px) 2px 100%,
        repeating-linear-gradient(to bottom,
          $border_dark_grey 0,
          $border_dark_grey 15px,
          transparent 15px,
          transparent 30px) 100% 2px;
      background-size: 100% 2px, 2px 100%;
      background-repeat: no-repeat;
    }

    .upload_content {
      color: $text_color;
    }

    &.uploading_state {
      border: 2px dashed $theme_purple;
      background-color: $card_tag_primary_bg;
    }
  }
}

// Uploaded Doc view

.uploaded_document_wrapper {
  .file_name {
    color: $theme_purple;
  }

  .file_details_wrapper {
    .file_size {
      color: $text_color;
    }

    .spinner {
      border: 2px solid $color_light_gray_var_one;
      border-top: 2px solid $color_purple;
    }

    .uploading_text {
      color: $text_color;
    }
  }

  .error_container {
    color: $color_white;
    background-color: $color_red;
  }
}

// DropDown
.dropdown {
  .dropdown_input {
    border: 2px solid $medium_dark_gray;
    background-color: $dropdown_menu_bg_color;
    color: $text_color;

    &.selected_input {
      border: 2px solid $theme_purple;
    }
  }

  .placeholder {
    color: $text_dim_gray;
  }

  .dropdown_menu {
    border: 2px solid $theme_purple;
    background-color: $theme_bg_color;

    .dropdown_item:hover {
      background-color: $dropdown_menu_bg_color;
    }

    .dropdown_item.selected {
      // background-color: $dropdown_menu_bg_color;
      color: $theme_purple;
    }
  }
}

.succession_plan_container {
  .label {
    border-bottom: 2px solid $text_color;
  }
}

.action_plan_document_container {
  .label {
    border-bottom: 2px solid $text_color;
  }
}

//css for the formatter options

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #d8d8d9;
}

.ql-toolbar .ql-fill {
  fill: #d8d8d9;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #d8d8d9;
}

.ql-editor::before {
  color: $grey_color_2;
}

// Myaccount and leg analysis
.profile_table {
  .header_cell {
    &:first-child {
      background-color: $dark-charcoal;
    }
  }

  .tbl_data_row {
    .data_cell {
      background-color: $dark-charcoal;
      border: 1px solid $theme_bg_color;

      .table_info {
        .labelInfo {
          @include underline-rounded($text_color, 2px, 1px);
        }
      }

      &:first-child {

        border-right: 1px solid $theme_bg_color  !important;
      }

      &:nth-child(2) {
        border-left: 0
      }

      &:last-child {
        border-right: 1px solid $theme_bg_color  !important;
      }

      .data_item {
        border-right: 1px solid $border_dark_grey;

        .data_label {
          color: $text_dim_gray;
        }
      }

    }
  }

}

.data_cell {
  // background-color: $dark-charcoal;
  .info_logo_outer{
    background-color: $card_tag_primary_bg;
  }
  .info_logo_inner{
    color: $card_tag_primary_color;
  }
  .table_info {
    .labelInfo {
      @include underline-rounded($text_color, 2px, 1px);
    }
  }

  &:first-child {}

  &:last-child {}
}

// Progress Notes
.notes_container {
  .input_text_area {
    background-color: $dark_charcoal;
    border: 1px solid $dark_charcoal;
    color: $text_dim_gray;

    &.text_area_onchange {
      background-color: $dark_charcoal;
      border: 2px solid $theme_purple;
    }
  }

  .progress_note {
    border-bottom: 1px solid $dark_charcoal;

    &:last-child {
      border-bottom: 0;
    }
  }

  .initials {
    color: $theme_purple;
    border: 1px solid $dark_charcoal;
    background: $dark_charcoal;
  }

  .last_modified {
    color: $text_dim_gray;
  }

  .note_content {
    color: $text_dim_gray;
  }
}

// search dropdown
.user_profile_dropdown {

  // box-shadow: #000000D6;
  background: $dark-charcoal;
  box-shadow: var(--X, 0px) var(--Y, 2px) var(--Blur, 16px) var(--Spread, 6px) var(--shadow-drop-shadow, rgba(0, 0, 0, 0.84));

  .search_dropdown_title {
    background-color: $dark-charcoal;
  }

  .user_profile_wrapper {
    border-bottom: 1px solid $theme_bg_color;

    .user_name {
      @include underline-rounded($text_color, 2px, 1px);
    }

    .user_info {
      color: $grey_color_2;

      .abo_num {
        border-right: 2px solid $border_dim_gray;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .no_result_text {
    color: var(--text-text-secondary, $grey_color_2);
  }
}

// For common
.profile_nav_btn {
  background-image: $right_arrow;
}



// Customize modal theme
.customize_view_modal {
  background-color: $dark_charcoal;

  .tabs_wrapper {
    background: var(--surface-surface-highlight, $border_dim_gray);

    .tablink {
      color: $grey_color_2;

      &.active_tab {
        color: var(--text-text-brand, $theme_purple);
        border-bottom: 2px solid $theme_purple;
      }
    }
  }

  .step_title {
    color: $grey_color_2;
  }

  .input_field {
    border: 1px solid $text_color;
    background-color: $secondary_theme_bg;
    color: $text_color;

    .input_with_text {
      color: $grey_color_2;
    }
  }

  .placeholder {
    color: $grey_color_2;
  }

  .bottom_button_wrapper {
    background: var(--surface-surface-highlight, $border_dim_gray);
    box-shadow: 0px -2px 6px 0px rgba(44, 44, 44, 0.10);
  }
}

// Checkbox
.checkbox_wrapper {
  .checkbox_label {
    color: $text_color;
  }
}

// Saved view card
.saved_view_card {
  background: var(--surface-surface-footer, $theme_bg_color);

  .tag {
    background: var(--surface-surface-deselected, $dark_charcoal);
    color: $theme_purple;
  }
}

// Error in page
.error_in_page_wrapper {
  border: 1px solid var(--border-border-primary, $dark_charcoal);
  background: var(--surface-surface-tertiary, $dark_charcoal);
}

//My Account
.myAccountContainer {
  .selected_view_btn {
    background-color: $theme_purple;
    color: $theme_bg_color;
    border: none;
  }
  .view_btn{
    border-color: #282828;
  }
}

// Comming Soon 
.coming_soon_section {
  border: 1px solid var(--border-border-primary, $dark_charcoal);
  background: var(--surface-surface-tertiary, $dark_charcoal);
}

// MYAccounts 
.section_title_head {
  color: $text_color;
  .btn_wrapper{
    .view_btn{
      border-color: dark_charcoal;
    }
  }
}

.login_container {
  .error_message {
    color: $color_red;
  }
}

.bottom_line {
  border-top: 1px solid $dark_charcoal;
}

.ap_view_profile_container {
  .ap_profile_image_wrapper {
    background-color: $theme_purple;
  }
}

// Tag Tab before selection
.tag_container {
  background-color: $theme_purple;

  .tag_label {
    color: $theme_bg_color;
  }
}

.tag_unselected {
  border: 1px solid $border_dark_grey;
}

// selected tab in note
.tab_tag_container {
  .tab_label {
    background: $secondary_theme_bg;
    color: $theme_purple;
  }

}

// Filter head tags 
.filter_head_wrapper {
  .filter_tag {
    border: 1px solid $border_dark_grey;
    background-color: $theme_bg_color;
  }

  .active_filter_tag {
    color: $theme_bg_color;
    background-color: $theme_purple;
  }
}

.ap_main_container {
  .ap_empty_state_container {
    border: 1px solid $dark_charcoal;
    background-color: $dark_charcoal;

    .no_ap_selected_text {
      color: $grey_color_2;
    }
  }
}

.business_performance {
  .insight_content_wrapper {
    background: var(--surface-surface-tertiary, #282828);
    color: $text_color;
  }

  .header {
    @include font_gt_walsheim_bold;

    .title {
      font-weight: bold;
      color: $text_color;
    }

    .playbook_link {
      color: $text_color;

      .playbook_text {
        &::after {
          background-color: $text_color;
        }
      }
    }

  }
}


// AGC tile
.agc_details_tile {
  .agc_tile_wrapper {
    border: none;
    background: var(--surface-surface-tertiary, #1C1C1E);

    .title {
      color: #D8D8D9;
    }

    .desc {
      color: #AEAEB2;
    }
  }
}

// BenchMark Comp
.benchmark_container {
  .benchmark_title_section {
    color: $text_dim_gray;
  }

  .label {
    color: #AEAEB2;
  }

  .marker_wrapper {
    color: #AEAEB2;
  }

  .progress_bar {
    background-color: #636366;
  }
}

// In AGC
.benchmark_value {
  background: #282828;

  .yoy_value {
    color: #D8D8D9;
  }

  .yoy {
    color: #D8D8D9;

    .rise {
      color: $success_green;
    }

    .fall {
      color: $color_red_faint;
    }
  }
}

// AI insights
.ai_insight_wrapper {
  background: var(--surface-surface-tertiary, #282828);

  .ai_title {
    color: #B2F9FF;
  }

  .update_date {
    color: #AEAEB2;
  }

  .ai_content {
    color: #D8D8D9;
  }
}

.summary_section {
  .tile_container {
    border: 1px solid $theme_bg_color;
    background-color: $dark_charcoal;

    .tile_main_wrapper {
      .tile_upper_section {
        .tile_header {
          color: $grey_color_2;
        }

        .tile_body {
          .tile_change_value_wrapper {
            .change_value {
              &.positive {
                color: $text_positive;
              }

              &.negative {
                color: $text_negative;
              }

              &.nill {
                color: $grey_color_2;
              }
            }
          }
        }
      }

      .separator_line {
        border-top: 1px solid $theme_bg_color;
      }

      .tile_lower_section {
        color: $grey_color_2;
      }
    }
  }

  .title {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: $grey_color_2;
  }

  .tile_value {
    color: $text_color;
  }

  .tile_yoy {
    color: green;
  }
}

// Graph Card
.graph_card_container {
  border: none;
  background: var(--surface-surface-tertiary, #282828);

  .card_footer {
    .current_month_year {
      color: var(--text-text-secondary, #AEAEB2);
    }

    .data_value {
      color: #99B2F5;
    }

    .value_info_wrapper {
      .value_diff {
        color: #AEAEB2;
      }

      &.raised_change {
        .percentage_value_change {
          color: $success_green;
          background: #1C1C1E;
        }
      }

      &.fall_change {
        .percentage_value_change {
          color: $color_red_faint;
          background: #1C1C1E;
        }
      }
    }
  }

  .last_closed_month_wrapper {
    .last_closed_month {
      color: $grey_color_2;
    }
  }

  .insight_container {
    .ai_insight {
      color: #B2F9FF;
    }

    background-color: $card_tag_primary_bg;

    .update_section {
      .update_text {
        color: $grey_color_2;
      }
    }
  }
}

// Common Benchmark component
.benchmark_wrapper {
  .benchmark {
    color: $grey_color_2;

    .benchmark_track {
      background-color: $border_dark_grey;

      .benchmark_sections {
        border-right: 2px solid $theme_bg_color;
        border-left: 2px solid $theme_bg_color;
        .benchmark_section {
          border-right: 2px dashed $theme_bg_color;

          &.middle {
            border-right: 2px solid $theme_bg_color;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

// Year table/chart
.annual_tbl_wrapper {
  border: 1px solid var(--border-border-secondary, #000);

  .tbl_head {
    th {
      background: var(--surface-surface-primary, #000);
    }
  }

  .tbl_row {
    &.even_row {
      background: var(--surface-surface-secondary, #282828);
    }

    &.odd_row {
      background: var(--surface-surface-primary, #000);
    }

    .positive {
      color: $success_green;

    }

    .negative {
      color: $color_red_faint;
    }
  }
}

.tooltip_wrapper {
  .tooltip {
    // color: #000000;
    background-color: $card_tag_primary_color;

    &::after {
      border-top: 6px solid $card_tag_primary_color;
    }
  }

  .tooltip_text {
    color: $theme_bg_color;
    // font-family: "GT Walsheim Pro", sans-serif;
  }

  .bold_text {
    color: $theme_bg_color;
  }
}

// 90 days Activation rate 
.activation_wrapper {
  background-color: $dark-charcoal;

  .rate_period {
    color: $theme_purple;

    &.selected_period {
      color: $dropdown_menu_bg_color;
      background-color: $theme_purple;
    }
  }
}

// KPI coming soon
.kpi_coming_soon {
  border: 1px solid var(--border-border-secondary, #000);
  background: var(--surface-surface-tertiary, #282828);

  .kpi_text {
    color: #AEAEB2;
  }
}





// AI Chat Bot 

// Profile
.profile_card {
  border: 1px solid #000;
  background-color: #282828;
  &:hover{
    border: 0.5px solid #77CBBF;
  }
  .flag_wrapper{
    background:  #1C1C1E;
    .red_flag {
      color: #F16A81;
    }
    .yellow_flag {
      color: rgb(254 215 115)
    }
  }
  .violation_desc {
    color: var(--text-text-secondary, #AEAEB2);
    .bold_kpi_value {
      color: #D8D8D9;
    }
  }
}

// violation
.violation_container {
  .flag_wrapper{
    background:  #1C1C1E;
    .red_flag {
      color: #F16A81;
    }
    .yellow_flag {
      color: rgb(254 215 115)
    }
  }
  .violation_desc {
    color: $grey_color_2;
  }
}

.followup_container{
  background-color: $theme_bg_color;
  .question_text {
    color: #77CBBF;
  }
  .followup_heading{
    color: #D8D8D9;
  }

}
.question_text {
  color: #77CBBF;
}

.followup_item{
  color: #AEAEB2;
  border-top: 1px solid #282828;
  &:hover{
    .followup_text{
      color: #D8D8D9;
    }
  } 
  .followup_arrow{
    background-color: #000000;
} 
}

.markdown_styled {
  color: $text_color;
}
.abo_list_container{
  .inner_wrappwer{
    background-color: #282828;
    border: 1px solid #000;
    .try_again{
      color: #D8D8D9;
    }
  }
}
.report_wrapper{
  .report_heading{
    color: #D8D8D9;
  }
  .report_subheading{
    color: #AEAEB2;
  }
  .card{
    background-color: #282828;
    .card_heading{
      color: #99B2F5;
    }
    .card_content{
      color: #D8D8D9;
    }
}
  a {
    color: #B2F9FF;
    border-bottom: 2px solid #B2F9FF;
  }
}
.drawer_container{
  background-color: red($color: #000000);
  .drawer_backdrop{
    background-color: rgba(0, 0, 0, 0.7);
  }
  .drawer{
    background-color: #282828;
    .drawer_content{
      color: #d8d8d9;
    }
  }
}
.hatvSubheaderContainer{
  background-color: #000000;
  border-bottom: 1px solid #282828;
}