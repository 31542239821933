@import "../../../App.scss";

.profile_card {
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: transform 0.3;
  margin-bottom: 16px;
  display: flex;
  &:last-child{
    margin-bottom: 0;
  }

  &:hover {
    transform: scale(1.02);
    .arrow_icon {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .content_container {
    width: 708px;
  }

  .abo_name {
    font-size: 18px;
    line-height: 21px;
    @include font_gt_walsheim_bold;
    margin-bottom: 4px;
  }

  .abo_details {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    margin-bottom: 12px;
  }

  .violation_status {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .violation_text {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;
      margin-right: 8px;
    }

    .flag_wrapper {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 4px;
    
      .flag_type {
        font-size: 14px;
        line-height: 18px;
        @include font_gt_walsheim_medium;
      }
   
      .flag {
        margin-right: 4px;
      }
    }
  }

  .violation_desc {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    .kpi_details {
      display: inline;
      .kpi_wrapper {
        display: inline;
        white-space: nowrap;
        margin-right: 8px;
      }
      .bold_kpi_value{
        @include font_gt_walsheim_bold;
      }
    }
  }

  .arrow_icon {
    opacity: 0;
    pointer-events: none;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

}
