@import "../../../../styles/all.scss";

.ap_info_details_container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  .inner_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .section_wrapper {
      margin-bottom: 20px;
    }
    .section_label {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}
