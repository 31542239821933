.loader {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;
  &.medium {
    width: 20px;
    height: 20px;
  } 
  &.large {
    width: 30px;
    height: 30px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
