@import "../../../../../App.scss";

.card_holder {
  // width: 25%; /* 25% for 4 cards in a row */
  display: inline-block;
  padding: 0px 12px 12px 0;
  width: 50%;
  @include breakpoint(md) {
    width: 25%;
  }
  &:last-child {
    padding-right: 0;
  }
}
.card {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  height: 385px;
  border-radius: 12px;
}

.card_profile {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
}

.card_logo_outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #38539a;
  gap: 10px;
}

.card_logo_inner {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  @include font_gt_walsheim_bold;
  color: #2c2c2c;
  font-size: 16px;
  line-height: 24px;
}

.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.name {
  align-self: stretch;
  @include font_gt_walsheim_medium;
  font-size: 18px;
  line-height: 21px;
}
.localName {
  @include font_gt_walsheim_medium;
  font-size: 18px;
  line-height: 21px
}
.tagOuterPrimary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  .cardTag {
    display: flex;
    height: 24px;
    padding: 2px 4px;
    gap: 4px;
    align-items: flex-start;
    gap: 10px;
    @include font_gt_walsheim_medium;
    font-size: 14px;
    line-height: 18px;
  }
}

.tagOuterNonPrimary {
  display: flex;
  height: 24px;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  .cardTag {
    display: flex;
    height: 24px;
    padding: 2px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    @include font_gt_walsheim_medium;
    font-size: 14px;
    line-height: 18px;
  }
}
.card_info {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow-y: auto;
}

.personalInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
}

.infoHeader {
  @include font_gt_walsheim_medium;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding-bottom: 4px;
}
.info {
  @include font_gt_walsheim_medium;
  font-size: 14px;
  line-height: 18px;
  @include ellipsis;
  max-width: 95%;
}
