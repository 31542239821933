@import '../../../App.scss';

.drawer_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  z-index: 1009;
  display: flex;
  justify-content: flex-end; 
  
  .drawer_backdrop {
    flex-grow: 1; 
  }
    .drawer {
    width: 480px; 
    height: 100%; 
    margin-bottom: 16px; 
    padding: 24px 24px 16px; 
    overflow-y: auto; 
    display: flex;
    flex-direction: column; 
    
    .drawer_header {
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: -20px;
      .drawer_heading{
        @include font_gt_walsheim;
        font-size: 21px;
        // padding-top: 22px;
        line-height: 24px;
        // padding-bottom: 15px;
        padding: 16px;
      }
    }
    
    .close_icon {
      position: absolute;
      right: 0;
      top: 20px;
      cursor: pointer;
    }
    
    .drawer_content {
      font-size: 16px; 
      line-height: 24px; 
      flex-grow: 1;
      margin-bottom: 16px;
    }
  }
}
