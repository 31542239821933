@import "../../../App.scss";

.sidebar_container {
  width: 165px;
  margin-right: 16px;
  // border: 1x solid #707070;
  height: 100%;
  flex-shrink: 0;
  @include font_gt_walsheim;
  position: sticky;
  top: 180px;
  .section {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .section_title {
    @include font_gt_walsheim_bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    cursor: pointer;
    @include breakpoint(lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .items {
    list-style: none;
    margin: 0;
  }

  .items .link_item {
    cursor: pointer;
    padding: 12px 16px;
    // margin: 12px 0;
    &.disabled_tab {
      pointer-events: none;
    }
    &.selected_link {
      padding: 12px 16px 12px 12px;
      @include font_gt_walsheim_medium;
    }
    .link_title {
      font-size: 14px;
      line-height: 18px;
      @include breakpoint(lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .coming_soon {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      font-style: italic;
      @include font_gt_walsheim_medium;
      @include breakpoint(lg) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    @include font_gt_walsheim;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include breakpoint(lg) {
    margin-right: 32px;
    width: 200px;
    top: 188px;
  }
}
