@import "../../../App.scss";

.saved_view_card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 62px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: var(--size-small, 12px);

  .title {
    flex-grow: 1;
    font-size: 16px;
    @include font_gt_walsheim_medium;
    line-height: 27px;
    max-width: 200px;
    @include ellipsis_two_liner;
  }

  .tag {
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 24px;
    font-size: 14px;
    line-height: 18px;

  }
  .icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    .edit_icon {
      margin-right: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
    .delete_icon {
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  &:last-child {
    margin-bottom: 24px;
  }
}
