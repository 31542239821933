@import "../../../styles/all.scss";

.personal_info_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  .inner_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .section_wrapper {
      margin-bottom: 20px;
      position: relative;
    }
    .section_label {
      font-feature-settings:
        "clig" off,
        "liga" off;
      @include font_gt_walsheim;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}
