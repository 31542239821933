@import "../../../../App.scss";


.qualifications_wrapper{
    .heading_area {
        width: 100%;
        margin-bottom: 20px;
    
        .heading {
          @include font_gt_walsheim_bold;
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
          width: 70%;
          margin-bottom: 8px;
        }
        .sub_heading {
          @include font_gt_walsheim;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          //margin-bottom: 15px;
        }
      }
}