@import "../../../App.scss";

.customize_view_modal {
  padding: 50px 16px 24px;
  .modal_title {
    font-size: 21px;
    @include font_gt_walsheim_bold;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .tabs_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 -24px;
    height: 48px;
    margin-bottom: 24px;
    .tablink {
      width: 50%;
      padding: 12px 0px;
      background: none;
      border: none;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: border-color 0.4s;
      @include font_gt_walsheim_medium;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      &.active_tab {
        @include font_gt_walsheim_bold;
      }
    }
  }

  .steps_wrapper {

    max-height: calc(80vh - 186px);
    .checkbox_wrapper {
      margin-bottom: 24px;
      &:last-child {
        // margin-bottom: 96px;
      }
    }
    .steps_inner_wrapper {
      min-height: 152px;
      overflow-y: auto;
      max-height: inherit;
      height: auto;
      margin-bottom: 24px;
      padding-bottom: 64px;
    }
    &.third_step {
      min-height: 152px;
      overflow-y: auto;
      max-height: inherit;
      height: auto;
      margin-bottom: 24px;
      padding-bottom: 95px;
    }
  }
  .step_title {
    font-size: 16px;
    @include font_gt_walsheim;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .input_wrapper {
    position: relative;
    height: 56px;
    display: flex;
    margin-bottom: 32px;
    .input_field {
      width: 100%;
      height: 100%;
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;
      padding: 16px;
      border: 1px solid;
      border-radius: 12px;
      outline: none;
      box-sizing: border-box;
      &.input_with_text {
        padding: 20px 16px 7px;
        box-sizing: border-box;
        height: 56px;
      }
      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        color: #707070;
      }
      &:focus {
        border: 2px solid #5969d6;
      }
    }
    .placeholder {
      position: absolute;
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
      transition: all 0.2s ease;
    }
  }
  .input_wrapper.focused .placeholder,
  .input_wrapper .input_field:not(:placeholder-shown) + .placeholder {
    top: 7px;
    left: 16px;
    font-size: 14px;
    line-height: 18px;
  }
  .saved_view_card_wrapper {
    min-height: 150px;
    height: auto;
    max-height: calc(100vh - 365px);
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .bottom_button_wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: 84px;
    flex-shrink: 0;
    padding: 24px 32px;
    border-radius: 0px 0px 20px 20px;

    .bottom_button {
      display: flex;
      justify-content: center;
      align-items: center;
      Button {
        height: 36px;
        width: auto;
      }
    }
    .link_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .apply_without_save {
        text-align: center;
        border-bottom: 2px solid;
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim_bold;
        cursor: pointer;
      }
    }
  }
}
