@import "../../../App.scss";

.hatvSubheaderContainer {
    display: flex;
    padding: 8px 48px;
    align-items: center;
    align-self: stretch;
    position: fixed;
    width: 100%;
    height: 52px;
    z-index: 2;
    .ai_flower_logo {
        margin-right: 6px;
    }

    .headerText {
        @include font_gt_walsheim;
        font-size: 14px;
        line-height: 18px;
    }
}