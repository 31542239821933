@import "../../../App.scss";

.uploaded_document_wrapper {
  // padding-bottom: 24px;
  padding: 50px 16px 24px;
  .modal_title {
    font-size: 21px;
    line-height: 28px;
    @include font_gt_walsheim_bold;
    margin-bottom: 24px;
  }
  .inner_wrapper {
    .dropdown_wrapper {
      // margin-bottom: 24px;
    }
    .file_info {
      margin-bottom: 24px;
      .file_info_wrapper {
        display: flex;
        position: relative;
        justify-content: space-between;
        .file_info_inner_wrapper {
          .file_name {
            font-size: 16px;
            line-height: 24px;
            @include font_gt_walsheim_medium;
            margin-bottom: 2px;
          }
          .file_details_wrapper {
            display: flex;
            .file_size {
              font-size: 14px;
              line-height: 18px;
              margin-right: 16px;
              @include font_gt_walsheim;
            }
            .spinner {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 4px;
            }
            .uploading_text {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
        .delete_icon_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          @include flex_center;
          cursor: pointer;
          .delete_icon {
            width: 13px;
            height: 14px;
            flex-shrink: 0;
          }
        }
      }
      .error_container {
        margin-top: 12px;
        width: 100%;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        padding: 8px 12px;
        border-radius: 12px;
      }
    }
    .btn_wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
