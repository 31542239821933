@import "../../App.scss";
.profile_container {
  padding: 16px 32px;
  //position: relative;
  flex: 1;
  .flex_wrapper {
    display: flex;
    align-items: flex-start;
    .content_wrapper {
      height: 100%;
      border-radius: 12px;
      width: calc(100% - 181px);
      @include breakpoint(lg) {
        width: calc(100% - 224px);
      }
      .inner_wrapper {
        display: flex;
        //align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        // .section_wrapper {
        //   margin-bottom: 20px;
        // }
      }
    }
  }
  @include breakpoint(lg) {
    padding: 16px 48px;
  }
}

.active_section {
  display: block;
  margin-bottom: 200px;
}

.hidden_section {
  display: none;
}