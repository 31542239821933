@import "../../../../App.scss";

.violation_container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .ai_logo {
    margin-right: 16px;
  }

  .abo_name {
    font-size: 18px;
    line-height: 21px;
    @include font_gt_walsheim_bold;
    margin-bottom: 4px;
  }

  .abo_details {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    margin-bottom: 12px;
  }

  .violation_status {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .violation_text {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;
      margin-right: 8px;
    }

    .flag_wrapper {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 4px;

      .flag {
        margin-right: 4px;
      }
    }
  }

  .violation_desc {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .kpi_details {
      display: inline;
      .kpi_wrapper {
        display: inline;
        white-space: nowrap;
        margin-right: 8px;
      }
      .bold_kpi_value{
        @include font_gt_walsheim_bold;
      }
    }
  }

  .linkText {
    font-size: 16px;
    line-height: 24px;
    @include font_gt_walsheim;
  }

  .linkLabel {
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    text-underline-offset: 4px;
    @include font_gt_walsheim_medium;
    display: flex;
    align-items: center;
  }

  .linkContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .linkIcon {
      margin-top: 4px;
      margin-left: 5px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
}