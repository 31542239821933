@import "../../../App.scss";

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  overflow: hidden;
}

.modal_container{
  padding: 48px;
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 48px 16px;


    .modal_content {
      display: none;
      width: 472px;
      height: fit-content;
      min-height: 200px;
      max-height: 80vh;
      z-index: 97;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      &.active {
        display: block;
      }
      .close_icon {
        cursor: pointer;
        width: 14px;
        height: 16px;
        flex-shrink: 0;
        margin-left: auto;
        position: absolute;
        top: 20px;
        right: 16px;
      }
    }
  }
}



