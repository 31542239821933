@import '~react-quill/dist/quill.snow.css';

.ql-editor {
font-family: "gt-walsheim-regular", sans-serif;
font-weight: 400;
font-size: 16px;
}
.ql-editor::before {
font-family: "gt-walsheim-medium", sans-serif !important;
font-style: unset !important;
}


.ql-editor strong{
	font-weight:700;
	font-family: "gt-walsheim-bold", sans-serif;
}
.ql-editor em {
	font-style: italic;
	}
.ql-toolbar.ql-snow .ql-formats {
	margin-right: 0 !important;
}