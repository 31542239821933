@import "../../../styles/all.scss";

.user_profile_dropdown {
  position: relative;
  width: 100%;
  min-height: 98px;
  //max-height: 350px;
  border-radius: 12px;
  max-height: 422px;
  overflow-y: auto;
  z-index: 4;
  padding: 0 12px 12px;
  &.loading_overflow_hidden {
    overflow: hidden;
  }
  .search_dropdown_title{
    font-size: 18px;
    line-height: 21px;
    @include font_gt_walsheim_bold;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    padding-top: 12px;
    z-index: 1;
  }
  .user_profile_wrapper {
    padding: 12px 0;
    display: flex;
    align-items: center;

    &:last-child{
      border-bottom: 0;
    }
    .user_profile {
      display: flex;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 16px;
      .profile_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        flex-shrink: 0;
        border-radius: 95px;
      }
    
      .initials {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 21px;
        @include font_gt_walsheim_bold;
        color: #2c2c2c;
        border-radius: 95px;
        background: linear-gradient(90deg, #24C072 4.91%, #C3EB82 100%), var(--text-text-primary, #2C2C2C);
      }
    }
    
    .user_profile_details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 64px);
      
      .user_details {
        display: flex;
        flex-direction: column;
        width: calc(100% - 28px);
        .user_name {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
          text-transform: capitalize;
          max-width: 95%;
          width: fit-content;
          @include ellipsis;
        }
        
        .user_info {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 18px;
          @include font_gt_walsheim_medium;

          .abo_num {
            padding-right: 5px;
            margin-right: 5px;
          }
          
          .territory {
            @include ellipsis;
            max-width: 68%;
          }
        }
      }
      
      .profile_nav_btn {
        margin-left: auto;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }
  }
  .no_result_text {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    padding: 12px 0;
  }
  .bold_text {
    @include font_gt_walsheim_bold;
    font-size: 16px;
    line-height: 24px;
  }
}



