.ql-indent-1 {
	padding-left: 3em;
  }
.ql-indent-2 {
	padding-left: 6em;
  }
.ql-indent-3 {
	padding-left: 9em;
  }
.ql-indent-4 {
	padding-left: 12em;
  }
 .ql-indent-5 {
	padding-left: 15em;
  }
.ql-indent-6 {
	padding-left: 18em;
  }
.ql-indent-7 {
	padding-left: 21em;
  }
  .ql-indent-8 {
	padding-left: 24em;
  }