@import "../../../App.scss";

.graph_card_container {
  border-radius: 24px;
  padding: 16px;
  .graph_wrapper {
    padding-left: 24px;
  }
}

.line_icon {
  margin-right: 100px;
}

// 
.card_header {
  .legend_wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    .legend {
      display: flex;
      align-items: center;
      margin-right: 8px;
      .line_type {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      &:last-child {
        margin-right: 24px;
      }
    }
  }

}

.chart_wrapper {

}

.card_footer {
  .month_data {
    .current_month_year {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      @include font_gt_walsheim_medium;
      margin-bottom: 8px;
    }
    .data_value {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      @include font_gt_walsheim_bold;
      margin-bottom: 8px;
    }
    .value_info_wrapper {
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
      min-height: 24px;
      padding: 4px;
      .percentage_value_change {
        border-radius: 4px;
        padding: 2px 2px 2px 4px;
        margin-right: 4px;
      }
    }
    .value_diff {
      font-size: 12px;
      line-height: 18px;
      @include font_gt_walsheim;
    }
  }

}