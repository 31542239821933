@import "../../../styles/all.scss";

.GAP_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  .GAP_inner_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .section_wrapper {
      margin-bottom: 20px;
    }
    .section_label {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}
.viewmore_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 0;
  .viewmore_label {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    font-feature-settings:
      "clig" off,
      "liga" off;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}
