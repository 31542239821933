@import "../../../App.scss";

.followup_container {
  .question_text {
    margin-bottom: 32px;
  }
  .tbl_wrapper {
    margin-bottom: 40px;
  }

  .followup_heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 24px;
    @include font_gt_walsheim_medium;
    text-transform: uppercase;
  }  
}