@import "../App.scss";

.layout_container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .main_layout_content {
    padding-top: 156px;
  }
}
